import { useMemo } from 'react'
import { useTable, ColumnInstance, Row } from 'react-table'
import { CustomHeaderColumn } from './columns/CustomHeaderColumn'
import { CustomRow } from './columns/CustomRow'
import { useQueryResponseData, useQueryResponseLoading } from '../core/QueryResponseProvider'
import { usersColumns } from './columns/_columns'
import { User } from '../core/_models'
import { UsersListLoading } from '../components/loading/UsersListLoading'
import { UsersListPagination } from '../components/pagination/UsersListPagination'
import { KTCardBody } from '../../../../../../_metronic/helpers'
import { useGlobaleContext } from '../../../user-management/PersonalizeContext'

const UsersTable = () => {

  const { filteredTrackData, startIndex, endIndex } = useGlobaleContext()
  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              <td>titre page</td>
              <td className='text-center'>type</td>
              <td className='text-center'>date</td>
              <td className='text-center'>URL actuelle</td>
              <td className='text-center'>lien href </td>
              <td className='text-center'>titre boutton</td>
              <td className='text-center'>prix</td>
              <td className='text-center'>total</td>
              {/* <td className='text-center'>Actions</td> */}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' >
            {filteredTrackData.length > 0 ? (
              filteredTrackData.slice(startIndex, endIndex).map((item: any) => {
                return <CustomRow {...item} key={item.id} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <UsersListPagination />
      {/* {isLoading && <UsersListLoading />} */}
    </KTCardBody>
  )
}

export { UsersTable }
