import { FC, useState, useEffect } from 'react'
import { AES, enc } from 'crypto-js'
import clsx from 'clsx'
import { useGlobaleContext } from '../../../user-management/PersonalizeContext' 



const UserEditModalForm: FC<any> = () => {
  const { way, setWay, def, setDef, handleWay, handlePut, cancel, pictoChoices, AddPersonalise, setPersonalizeItem, personalizeItem, secret } = useGlobaleContext();
  useEffect(() => {
    if (!personalizeItem.btntitle) {
      setWay(true);
    } else {
      setWay(false)
    }
  }, [personalizeItem.btntitle])
  console.log('whatWay',way)
  return (
    <>
      <form id='kt_modal_add_user_form' className='form' noValidate> {/*personalizeItem.id ? handlePut(e, personalizeItem.id) :*/}
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <button
            onClick={() => handleWay()}
            type='button'
            className='btn  me-3 mb-5 fs-3 text-white bg-danger '
          >
            {!way ? 'hard code votre boutton !!' : 'personaliser votre boutton'}
          </button>


          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Domaine</label>
            <textarea
              placeholder='https://example.com'
              name='name'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
              )}
              autoComplete='off'
              value={personalizeItem.domaine}
              onChange={(e) => { setPersonalizeItem({ ...personalizeItem, domaine: e.target.value }) }}
            />

          </div>
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>image</label>
            <input
              placeholder='https://example.com'
              type='file'
              name='name'
              className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
              autoComplete='off'
              onChange={(e) => {
                const file = e.target.files && e.target.files[0];
                if (file) {
                  setPersonalizeItem({ ...personalizeItem, image: file });
                };
              }}
            />


          </div>

          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Active</label>

            <input
              className=" ms-4  text-primary bg-light border-light rounded outline-0 border-dark"
              type="checkbox"
              checked={personalizeItem.active} value={personalizeItem.active} onChange={() => setPersonalizeItem({ ...personalizeItem, active: !personalizeItem.active })}
            />


          </div>

          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Produits Authorisés</label>
            <textarea
              placeholder='123132,456123'
              name='name'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
              )}
              autoComplete='off'
              value={personalizeItem.authorizedProducts}
              onChange={(e) => { setPersonalizeItem({ ...personalizeItem, authorizedProducts: e.target.value }) }}

            />
            <span className='text-danger'> à séparer avec virgule </span>

          </div>
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Inserer Après</label>
            <input
              placeholder='Inserer Après'
              type='text'
              name='name'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
              )}
              autoComplete='off'
              onChange={(e) => { setPersonalizeItem({ ...personalizeItem, insertAfterClass: e.target.value }) }}
              value={personalizeItem.insertAfterClass}
            />

          </div >
          {way == true && (
            <>


              <label className='required fw-bold fs-6 mb-2'>Personnaliser le boutton</label>
              <div className='' >

                <label className=' fw-bold fs-6 mb-2'>coder</label>

                <input
                  className=" ms-4  text-primary bg-light border-light rounded outline-0 border-dark"
                  type="checkbox"
                  checked={def == true} onChange={() => setDef((prev: boolean) => !prev)}
                />
              </div>
              {def ? (<div className='fv-row mb-7'>
                <textarea
                  placeholder="<button class='bootstrap'> trouver votre taille !</button>"
                  name='name'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                  )}
                  autoComplete='off'
                  value={personalizeItem.button}
                  onChange={(e) => { setPersonalizeItem({ ...personalizeItem, button: e.target.value }) }}
                />
              </div>) : (
                <textarea
                  placeholder="<button class='bootstrap'> trouver votre taille !</button>"
                  name='name'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                  )}
                  autoComplete='off'
                  value={`${way ? "<div class='btn btn-primary btn-lg' ><span class='input-group-prepend'> <span><i class='fa fa-search'></i></span> </span>Trouver votre taille!!`} </div>" :null }`}
                  //value={personalizeItem.button}
                  onChange={(e) => { setPersonalizeItem({ ...personalizeItem, button: e.target.value }) }}
                />
              )}

            </>
          )}

          {way == false && (
            <>
              <div className='fv-row mb-7'>
                <label className='required fw-bold fs-6 mb-2'>Titre</label>
                <input
                  placeholder='Titre'
                  type='text'
                  name='name'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                  )}
                  autoComplete='off'
                  value={personalizeItem.btntitle}
                  onChange={(e) => { setPersonalizeItem({ ...personalizeItem, btntitle: e.target.value }) }}

                />

              </div>
              <div className='fv-row mb-7'>
                <label className='required fw-bold fs-6 mb-2'>Background</label>
                <input
                  placeholder='Background'
                  type='text'
                  name='name'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                  )}
                  autoComplete='off'
                  value={personalizeItem.btnbackground}
                  onChange={(e) => { setPersonalizeItem({ ...personalizeItem, btnbackground: e.target.value }) }}

                />

              </div>
              <div className='fv-row mb-7'>
                <label className='required fw-bold fs-6 mb-2'>Couleur</label>
                <input
                  placeholder='Couleur'
                  type='text'
                  name='name'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                  )}
                  autoComplete='off'
                  value={personalizeItem.btntextcolor}
                  onChange={(e) => { setPersonalizeItem({ ...personalizeItem, btntextcolor: e.target.value }) }}

                />

              </div>
              <div className='fv-row mb-7'>
                <label className='required fw-bold fs-6 mb-2'>Font</label>
                <input
                  placeholder='Font'
                  type='text'
                  name='name'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                  )}
                  autoComplete='off'
                  value={personalizeItem.btnfontsize}
                  onChange={(e) => { setPersonalizeItem({ ...personalizeItem, btnfontsize: e.target.value }) }}

                />

              </div>
              <div className='fv-row mb-7'>
                <label className='required fw-bold fs-6 mb-2'>Picto</label>
                <select
                  className="form-control form-control-solid mb-3 mb-lg-0 cursor-pointer"
                  onChange={(e) => { setPersonalizeItem({ ...personalizeItem, picto: e.target.value }) }}
                  value={personalizeItem.picto}
                >
                  <option value="" className=''>--- Choisir Une Type ---</option>
                  {pictoChoices.map((item: any, index: number) => (
                    <option key={index} value={item.value} className=''>{item.title}</option>
                  ))}
                </select>

              </div>
            </>
          )}
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>BRAND_ID</label>
            <input
              placeholder='BRAND_ID'
              type='number'
              defaultValue={"000"}
              name='name'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
              )}
              autoComplete='off'
              value={personalizeItem.brand}
              onChange={(e) => { setPersonalizeItem({ ...personalizeItem, brand: Number(e.target.value) }) }}

            />

          </div>
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>REACT_APP_X_AUTH_TOKEN</label>
            <textarea
              placeholder='REACT_APP_X_AUTH_TOKEN'
              name='name'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
              )}
              autoComplete='off'
              value={personalizeItem.token}
              onChange={(e) => { setPersonalizeItem({ ...personalizeItem, token: e.target.value }) }}

            />

          </div>
        </div>
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
          // disabled={formik.isSubmitting || isUserLoading}
          >
            Anuller
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            onClick={(e) => personalizeItem.id ? handlePut(e, personalizeItem.id) : AddPersonalise(e)}
          // data-kt-users-modal-action='submit'
          >
            <span className='indicator-label'>Submit</span>

          </button>
        </div>
        {/* end::Actions */}
      </form>
      {/* {(formik.isSubmitting || isUserLoading) && <UsersListLoading />} */}
    </>
  )
}

export { UserEditModalForm }