import React, { useState, useEffect, useContext, createContext } from 'react'
import axios from '../../../utils/axios';
// import XLSX from 'xlsx';
import { useQueryResponse } from './users-list/core/QueryResponseProvider';
import { useListView } from './users-list/core/ListViewProvider';
const klipfitContext = createContext();
const KlipfiProvider = ({ children }) => {
    const { setItemIdForUpdate } = useListView()
    const { refetch } = useQueryResponse()
    const cancel = (withRefresh) => {
        if (withRefresh) {
            refetch()
        }
        setItemIdForUpdate(undefined)
        setPersonalizeItem({});
    }
    const openAddUserModal = () => {
        setItemIdForUpdate(null)
    }
    const [personalizes, setPersonalizes] = useState([])
    const [personalizeItem, setPersonalizeItem] = useState({ id: '', image: '', whatcase: '', button: "", brand: '', token: '', domaine: '', active: '', authorizedProducts: '', insertAfterClass: '', btntitle: '', btnbackground: '', btntextcolor: '', btnfontsize: '', picto: '' })
    const [isOpen, setIsOpen] = useState(false)
    const [page, setPage] = useState(1)
    const [way, setWay] = useState(false);
    const [def, setDef] = useState(true);
    const handleWay = () => setWay((prev) => !prev);
    const [searchTerm, setSearchTerm] = useState();
    const [filteredData, setFilteredData] = useState(personalizes)
    useEffect(() => {
        console.log('wayyy', way)
        if (way == true && def==false ) {
         setPersonalizeItem({ ...personalizeItem, button: "<button class='btn btn-primary btn-lg' ><span class='input-group-prepend'> <span><i class='fa fa-search'></i></span> </span> Trouver votre taille!! </button>" })
        // console.log("nothing");
        } else if (way == false) {
            setPersonalizeItem({ ...personalizeItem, button: "" })
        }
    }, [def])
    //get personalize
    function getPersonalize() {
        axios.get(`https://plugin.kleep.ai/getAll`, { headers: { "Content-Type": "application/json" } }).then((res) => {
            const allpersonalize = res.data;
            console.log('length', allpersonalize.length);
            setPersonalizes(allpersonalize)
            setFilteredData(allpersonalize)
        }).catch((error) => {
            console.error('Error fetching data:', error);
        })

    }
    useEffect(() => {
        getPersonalize()
    }, [page])

    //pagination
    const handlePage = (page) => {
        setPage(page)
    }
    //updated
    const AddPersonalise = async (e) => {
        e.preventDefault();
        const data = { ...personalizeItem, authorizedProducts: personalizeItem.authorizedProducts.split(',') }
        delete data.id
        if (way == false) {
            delete data.button
            data.whatcase = "P"
        } else if (way == true) {
            delete data.btntitle
            delete data.btnbackground
            delete data.btnfontsize
            delete data.btntextcolor
            delete data.picto
        }
        await axios.post("/personalizes", { ...data }, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
            .then((res) => {
                getPersonalize()
                cancel()
            }).catch((err) => {
                console.log('-----------UaliDMB---err--------');
                console.log(err);
                console.log('-----------UaliDMB-----------');

            })
    }
    const handleUpdate = (id) => {
        const selectedData = personalizes.find(el => el.id == id);
        console.log('selected', selectedData)
        setPersonalizeItem({ ...selectedData })
        openAddUserModal()
    }
    //change
    const handlePut = async (e, id) => {
        e.preventDefault();
        console.log('way', way);
        // hashToken(personalizeItem.token)
        const sure = window.confirm("Ètes-vous sûr de vouloir mettre à jour ?");
        const splited = Array.isArray(personalizeItem.authorizedProducts)
            ? personalizeItem.authorizedProducts
            : personalizeItem.authorizedProducts.split(',');
        let data = { ...personalizeItem, authorizedProducts: splited }
        if (way == false) {
            data.button = "";
            data.whatcase = "P"
        } else
            if (way == true) {
                data.btntitle = "";
                data.btnbackground = "";
                data.btnfontsize = "";
                data.btntextcolor = "";
                data.picto = "";
            }
        if (sure) {
            console.log('dataaa', data)
            await axios.post(`https://plugin.kleep.ai/update/${id}`, { ...data }, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
                .then((res) => {
                    getPersonalize()
                    cancel()
                })
                .catch((error) => {
                    console.log('-----------UaliDMB-----------');
                    console.log(error);
                    console.log('-----------UaliDMB-----------');
                });
        };
    };

    async function handleDelete(id) {
        const sure = window.confirm("Etes-vous sûr que vous voulez supprimer ?");
        if (sure) {
            await axios.delete("/personalizes/" + id).then((res) => {
                getPersonalize()
            })

        }
    }
    // const handleDelete = (id) => {
    //     const xhr = new XMLHttpsRequest();
    //     xhr.open('DELETE', "htt`://ec2-3-250-33-64.eu-west-1.compute.amazonaws.com/api/personalizes/${id}`, true);
    //     xhr.setRequestHeader('User-Agent', '*');
    //     xhr.setRequestHeader('Accept', '*/*');

    //     xhr.onreadystatechange = () => {
    //       if (xhr.readyState === 4 && xhr.status === 200) {
    //         // Request completed successfully
    //         console.log('Delete request successful');
    //       } else {
    //         // Request failed
    //         console.error('Delete request failed');
    //       }
    //     };

    //     xhr.send();
    //   };
    const pictoChoices = [
        { value: 'left', title: 'gauche' },
        { value: 'right', title: 'droite' },
        { value: 'no', title: 'sans' },
    ]
    // const exportToExcel = (data) => {
    //     // Create a new workbook
    //     const workbook = XLSX.utils.book_new();

    //     // Create a new worksheet
    //     const worksheet = XLSX.utils.json_to_sheet(data);

    //     // Add the worksheet to the workbook
    //     XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    //     // Generate the Excel file
    //     XLSX.writeFile(workbook, 'data.xlsx');
    //   };
    const [track, setTrack] = useState([]);
    const [filteredTrackData, setFilteredTrackData] = useState({ pageTitle: "", actionTime: "", price: "", total: "", hrefLink: "", buttonTitle: "", currentURL: "", eventType: "" })//track
    const [date1, setDate1] = useState('')
    const [date2, setDate2] = useState('')
    async function getTrack() {
        await axios.get('').then((res) => {
            setTrack(res.data);
            setFilteredTrackData(res.data);
        }).catch((err) => {
            console.log(err);
        })
    }
    useEffect(() => {
        getTrack();
    }, [])
    useEffect(() => {
        if (date1 !== '' && date2 !== '') {
            const date1InMs = new Date(date1).getTime();
            const date2InMs = new Date(date2).getTime();
            console.log('-----------UaliDMB-track before filter----------');
            console.log(track);
            console.log('-----------UaliDMB-----------');

            setFilteredTrackData(track.filter(item => {
                const dateInMs = new Date(item.date.split("/").reverse().join("-")).getTime();
                console.log('-----------UaliDMB 1 item date-----------');
                console.log(new Date(item.date.split("/").reverse().join("-")).getTime());
                console.log('-----------UaliDMB-----------');
                console.log('-----------UaliDMB 2-----------');
                console.log(date1InMs);
                console.log('-----------UaliDMB-----------');
                return dateInMs >= date1InMs && dateInMs <= date2InMs;
            }));
            console.log('-----------UaliDMB date filtred data-----------');
            console.log(filteredData);
            console.log('-----------UaliDMB-----------');

        }
    }, [date1, date2, track]);

    const vider = () => {
        setDate1('');
        setDate2('');
    }
    useEffect(() => {
        const searchRegex = new RegExp(searchTerm, 'i');
        console.log('-----------UaliDMB-----------');
        console.log("START");
        console.log('-----------UaliDMB-----------');
        setFilteredData(personalizes.filter(item => [item.domaine].some(_ => searchRegex.test(_))));
    }, [searchTerm, personalizes]);

    //pagination
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 30;
    const totalPages = Math.ceil(personalizes.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };


    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    }

    const renderPagination = () => {
        const pages = [];
        for (let i = 1; i <= totalPages; i++) {
            pages.push(
                <button
                    key={i}
                    onClick={() => handlePageChange(i)}
                    // className={`  ${currentPage === i ? 'bg-blue' : ''} btn-custom-sm`}
                    className="btn "
                    style={{ backgroundColor: currentPage === i ? '#009ef7' : '', borderRadius: "1.5rem", padding: "2px 12px" }}
                >
                    {i}
                </button>
            );
        }
        return pages;
    };
    return (
        <klipfitContext.Provider value={{ date1, date2, vider, setDate1, setDate2, filteredTrackData, endIndex, startIndex, currentPage, totalPages, handlePreviousPage, handleNextPage, renderPagination, filteredData, searchTerm, setSearchTerm, way, setWay, def, setDef, handleWay, cancel, pictoChoices, personalizes, isOpen, handleUpdate, setIsOpen, personalizeItem, setPersonalizeItem, handleDelete, handlePut, AddPersonalise }}>
            {/* <klipfitContext.Provider value={{ filteredData, searchTerm, setSearchTerm, way, setWay, secret, def, setDef, handleWay, cancel, pictoChoices, personalizes, isOpen, handleUpdate, setIsOpen, personalizeItem, setPersonalizeItem, handleDelete, handlePut, AddPersonalise }}> */}
            {children}
        </klipfitContext.Provider>
    )
}
export default KlipfiProvider;
export const useGlobaleContext = () => {
    return useContext(klipfitContext);
}