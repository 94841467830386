import { KTCard } from "../../../../../_metronic/helpers"
import KlipfiProvider from "../../user-management/PersonalizeContext"
import { UsersListHeader } from "./components/header/UsersListHeader"
import { ListViewProvider, useListView } from "./core/ListViewProvider"
import { QueryRequestProvider } from "./core/QueryRequestProvider"
import { QueryResponseProvider } from "./core/QueryResponseProvider"
import { UsersTable } from "./table/UsersTable"
import { UserEditModal } from "./user-edit-modal/UserEditModal"


const TrackList = () => {
  const { itemIdForUpdate } = useListView()
  return (
    <>
      <KTCard>
        <UsersListHeader />
        <UsersTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <UserEditModal />}
    </>
  )
}

const UsersListWrapper = () => (
  <ListViewProvider>
    <QueryRequestProvider>
      <QueryResponseProvider>
        <KlipfiProvider>
          <TrackList />
        </KlipfiProvider>
      </QueryResponseProvider >
    </QueryRequestProvider>
  </ListViewProvider >
)

export { UsersListWrapper }
