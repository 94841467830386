import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { UsersListWrapper } from './users-list/trackList' 
const truckBreadcrumbs: Array<PageLink> = [
  {
    title: 'tracking',
    path: '/apps/user-info',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const TrackPage = () => {
  return (
    <>
      <PageTitle breadcrumbs={truckBreadcrumbs}>données client</PageTitle>
      <UsersListWrapper />
    </>
  )
}

export default TrackPage
