// @ts-nocheck
import { FC } from 'react'

const CustomRow: FC<Props> = ({ pageTitle,actionTime,price,total,hrefLink,buttonTitle,currentURL,eventType }) => {

  return (
    <tr className=''>
      <td
        className="text-start"
      >
        {pageTitle}
      </td>
      <td
        className="text-center"
      >
        {eventType}
      </td>
      <td
        className="text-center"
      >
        {actionTime}
      </td>
      <td
        className="text-center"
      >
        {currentURL}
      </td>
      <td
        className="text-center"
      >
        {hrefLink}
      </td>
      <td
        className="text-center"
      >
        {buttonTitle}
      </td>
      <td
        className="text-center"
      >
        {price}
      </td>
      <td
        className="text-center"
      >
        {total}
      </td>
      <td
        className="text-center"
      >
        {token}
      </td>
    

    </tr>
  )
}

export { CustomRow }
