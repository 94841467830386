/* eslint-disable react-hooks/exhaustive-deps */
import { RiRestartLine } from 'react-icons/ri';
import { KTIcon } from '../../../../../../../_metronic/helpers'
import { useGlobaleContext } from '../../../../user-management/PersonalizeContext';

const UsersListSearchComponent = () => {

  const { date1, date2, setDate1, setDate2, vider } = useGlobaleContext();



  return (
    // <div className='card-title'>
    <div className='d-flex flex-wrap align-items-center justify-content-center gap-12 position-relative my-1'>
    {/* <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' /> */}
    <div className='d-flex align-items-center gap-2 flex-grow-1'>
      <h3 className='fs-6'>de:</h3>
      <input
        type='date'
        data-kt-user-table-filter='search'
        className='form-control form-control-solid ps-14 flex-grow-1'
        value={date1}
        onChange={(e) => setDate1(e.target.value)}
      />
    </div>
    <div className='d-flex align-items-center gap-2 flex-grow-1'>
      <h3 className='fs-6'>à:</h3>
      <input
        type='date'
        data-kt-user-table-filter='search'
        className='form-control form-control-solid ps-14 flex-grow-1'
        value={date2}
        onChange={(e) => setDate2(e.target.value)}
      />
    </div>
    <button type='button' className='btn btn-light-primary me-3 p-2' onClick={vider()}>
      <RiRestartLine className='fs-2' />
    </button>
  </div>
  
    // </div>
  )
}

export { UsersListSearchComponent }
