import axios from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const AWS_API_URL = process.env.REACT_APP_AWS_API_URL
const AWS_API_TOKEN = process.env.REACT_APP_AWS_TOKEN

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`
export const AWS_LOGIN_URL = `${AWS_API_URL}/management-login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return AuthModel

export async function login(email: string, password: string) {
  const headers: HeadersInit = new Headers();
  if (AWS_API_TOKEN) {
    headers.set('authorizationToken', AWS_API_TOKEN);
  }
  return await fetch(AWS_LOGIN_URL, {
    method: "POST",
    headers: headers,
    body: JSON.stringify({
      username: email,
      password: password,
    }),
  })
  .then((res) => res.json())
  .then((res) => JSON.parse(res.body))
  .catch((e) => {
    console.log("API CALL ERROR: brand data -", e.message);
  });
}

export function loginTris(email: string, password: string) {
  console.log("here");
  const res = axios.post<AuthModel>(AWS_LOGIN_URL, {
    authorizationToken: AWS_API_TOKEN,
    username: email,
    password: password,
  })
  return res
}

export function loginBis(email: string, password: string) {
  const res = axios.post<AuthModel>(LOGIN_URL, {
    email,
    password
  })
  return res
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}
