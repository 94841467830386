/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { useQueryResponseLoading, useQueryResponsePagination } from '../../core/QueryResponseProvider'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { useGlobaleContext } from '../../../../user-management/PersonalizeContext'

const mappedLabel = (label: string): string => {
  if (label === '&laquo; Previous') {
    return 'Previous'
  }

  if (label === 'Next &raquo;') {
    return 'Next'
  }

  return label
}

const UsersListPagination = () => {
  const { totalPages,currentPage, handlePreviousPage, handleNextPage, renderPagination } = useGlobaleContext()

  return (
    <div className='row'>
  <div className='col-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
    {/* Add your content here */}
  </div>
  <div className='mt-2 col-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
        <ul className="pagination">
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <button className="page-link btn-primary" onClick={handlePreviousPage} disabled={currentPage === 1}>
              Previous
            </button>
          </li>
          {renderPagination()}
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <button className="page-link btn-primary" onClick={handleNextPage} disabled={currentPage === totalPages}>
              Next
            </button>
          </li>
        </ul>
  </div>
</div>

  )
}

export { UsersListPagination }
