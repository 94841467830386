import axios from "axios";
// import https from "https"

const api = axios.create({
  baseURL: 'https://plugin.kleep.ai/api',
  // baseURL: 'http://127.0.0.1:8000/api',
});

// Set the CORS headers
// api.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
// api.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET, POST, PUT, DELETE';
// api.defaults.headers.common['Access-Control-Allow-Headers'] = 'Origin, Content-Type, Accept';
// api.defaults.headers.common['Access-Control-Allow-Credentials'] = true;

export default api;
